.singleAdmin {
  min-height: 44px;
  padding: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.adminName {
  margin-left: 12px;
}

.gymAccess {
  font-weight: bold;
}
@media screen and (min-width: 555px) {
  .singleAdmin {
    padding: auto;
  }
}
