.margin-top {
  margin-top: 20px;
}

.refresh-div {
  display: inline-flex;
  align-items: center;
}

.margin-left {
  margin-left: 5px;
}
