.singleClient {
  width: 33%;
}
.singleClientName {
  font-size: 1.2rem;
  vertical-align: middle;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
}
.gymName {
  width: 33%;
}

.main {
  min-height: 44px;
  padding: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionButtons {
  display: flex;
}

.icon {
  padding: 0 10px;
  cursor: pointer;
}

@media screen and (min-width: 555px) {
  .singleClient {
    padding: auto;
  }
}
@media screen and (max-width: 768px) {
  .main {
    min-height: 44px;
    padding: 10px 0;
    width: 100%;
    display: block;
    align-items: center;
    justify-content: space-between;
  }
  .actionButtons {
    justify-content: space-between;
    margin-right: 12px;
    align-items: center;
    width: 100%;
  }
  .icon {
    width: 24px !important;
    height: 24px;
    padding: 0;
    margin-right: 10px;
  }
}
