.addPaymentMethod {
  margin: auto;
}

.promoCode {
  margin-left: 10px;
}

.promoCodeDiv {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.apply {
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.info {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 3px;
  margin-bottom: 10px;
}
