.ClassPassReport {
  &__total {
    &__label {
      font-weight: bold;
    }
  }
  &__list {
    &__header {
      display: flex;
      justify-content: space-between;

      font-weight: bold;
    }
    &__item {
      display: flex;
      justify-content: space-between;
      &__count {
        display: flex;
        svg {
          margin-left: 10px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  &__filter {
    border: solid 0.5px rgba(0, 0, 0, 0.15);
    padding: 1em;
    margin-bottom: 1em;
    display: flex;
    & > div {
      margin: 0 20px;
    }
  }

  &__classType {
    padding-top: 10px;
    &__checkbox {
      display: block;
      padding-top: 5px;
    }
  }
}

.filterPass {
  display: inline-flex;
  align-items: center;
}

.refreshIcon {
  margin-left: 5px;
}

@media screen and (max-width: 768px) {
  .ClassPassReport__filter {
    display: block;
    & > div {
      margin: 0;
    }
  }
}
