.root {
  button {
    font-weight: 700 !important;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
  }
}
.nowrap {
  white-space: nowrap;
}

.warningClassInfo {
  color: red !important;
  margin: 0px !important;
  font-weight: bold !important;
}

.adminActionButtonContainer {
  float: right;
  display: flex;
  * {
    margin: 0px 0px 0px 20px;
  }
}
