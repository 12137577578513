.container {
  padding-bottom: 33px;
  margin-bottom: 33px;
  border-bottom: 1px solid #dddddd;
  .cost {
    font-weight: bold;
  }
}

@media (min-width: 768px) {
  .container {
    padding: 0 0 0 45px;
    margin: 0 0 0 45px;
    border-bottom: none;
    border-left: 1px solid #dddddd;
    width: 50%;
  }
}

@media (min-width: 1280px) {
  .container {
    padding: 0 0 0 60px;
    margin: 0 0 0 60px;
  }
}
