.btnFirst {
  padding: 9px !important;
  margin-top: 32px;
}

.btn {
  padding: 9px !important;
}

.label {
  font-size: 1.1875rem;
  color: #333;
  font-weight: 300;
  margin-bottom: 9px;
}

.workingDays {
  margin-bottom: 16px;
  display: flex;
  & > div {
    margin-right: 15px;
  }
}
.WorkingDayField {
  width: 88%;
}

@media (max-width: 768px) {
  .workingDays {
    display: block !important;
    & > div {
      margin: 0;
    }
  }
  .WorkingDayField {
    margin-top: 20px;
    width: 100%;
  }
  .btn {
    padding: 9px !important;
    margin-left: 0px;
    margin-top: 10px;
  }

  .btnFirst {
    padding: 9px !important;
    margin-top: 10px;
  }
}

select {
  padding: 11px 23px;
}
