.listHeader {
  font-size: 19px !important;
  margin: 0 0 13px 0;
  text-transform: uppercase;
}
.header {
  display: flex;
  align-items: center;
}
.clientTable {
  th {
    &:last-child {
      width: 100px;
    }
  }
}
