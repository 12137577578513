.root {
  margin-top: 10px;
  * {
    display: block;
  }

  label {
    margin: 0px !important;
  }

  :global {
    .grommetux-select {
      margin-bottom: 20px !important;
      width: 300px !important;
    }
    .grommetux-number-input {
      height: 48px !important;
      margin-bottom: 20px !important;
      input {
        width: 450px !important;
      }
    }
  }
}

.configContainer {
  padding: 10px;
  border-radius: 10px;
  background: #eff0f1;
  width: 470px !important;
}

.validationMsg {
  font-size: 12px !important;
  color: #ca0e0b !important;
  margin: -17px 0 17px 0 !important;
  font-weight: 500 !important;
}
