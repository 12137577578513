.modalBtnContainer {
  position: absolute;
  right: 10px;
}
.classDetail {
  margin-bottom: 5px;
  .classDetailLabel {
    font-weight: bold;
  }
}
