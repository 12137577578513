.remainingCredits {
  padding-left: 20px;
  padding-right: 40px;
  padding-bottom: 20px;
}
.redirectButton {
  margin-top: 15px;
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
  display: block !important;
}
