.report__filter_debtors {
  margin-top: 10px;
  margin-bottom: 16px;
  display: inline-flex;
  align-items: flex-end;
  & > div {
    margin: 0 20px;
  }
}

.refresh_icon {
  margin-left: -34px;
  margin-bottom: 5px;
}

@media screen and (max-width: 768px) {
  .report__filter_debtors {
    flex-flow: row wrap;
  }

  .refresh_icon {
    margin-left: 0px;
    margin-bottom: 0px;
  }
}
