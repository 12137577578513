@import '../../../brandConfig/font-family/fonts.scss';
@media screen and (max-width: 768px) {
  .client-profile-add-product__modal {
    font-family: $brand-font-family;
    position: absolute;
    top: 50%;
    left: 0;
    bottom: auto;
    border: 1px solid #cccccc;
    background: white;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    margin-right: -45%;
    max-width: 700px;
    max-height: 500px;
    transform: none;
    z-index: 1002;
  }
}
