@import '../../../../brandConfig/colors/colors.scss';

.root {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;

  h3 {
    color: $brand-color;
    margin-bottom: 10px;
  }
}

.singlePass {
  width: 330px;
  border: 3px solid $brand-color-accent;
  border-bottom: none;
  position: relative;
  box-sizing: border-box;
}

.singlePassHeading {
  padding: 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.infoIcon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.explainer {
  display: flex;
  flex-flow: column wrap;
  gap: 1rem;
}
.explainerAction {
  display: flex;
  padding: 1rem;
  justify-content: center;
}

.details {
  padding: 20px;
  margin-bottom: 50px;
}

.description {
  font-style: italic;
  padding-left: 20px;
  font-size: 0.9em;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.label {
  font-weight: bold;
}

.data {
  text-align: right;
}

.actionBtn {
  position: absolute;
  bottom: 0;
  width: 326px;
  left: -1px;
}

@media (max-width: 400px) {
  .actionBtn {
    left: 0px;
    width: 100%;
  }
}
