.optionContainer {
  padding-bottom: 2rem;
  .categoryOption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #ccc;
    margin: 5px 0;
    .tagInput {
      border: none;
      border-bottom: 1px solid #ccc;
    }
  }
}

.icon {
  cursor: pointer;
}

.btnContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.optionsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
