.tableListHeader {
  margin: 0 !important;
}
.filter {
  display: flex;
  border: solid 1px rgba(0, 0, 0, 0.15);
  padding: 1em;
  width: 100%;
  background: lightgrey;
  h4 {
    margin: auto 20px auto 0px;
  }
  input {
    width: 80%;
    background: white;
  }
}

.header {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  button {
    margin: auto 0px !important;
    height: 48px;
  }
}
