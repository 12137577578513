.singleFAQ {
  min-height: 30px;
  padding: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.faq {
  margin-left: 12px;
}

@media screen and (min-width: 555px) {
  .singleFAQ {
    padding: auto;
  }
}
