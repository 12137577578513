@import '../../../../brandConfig/colors/colors.scss';

.root {
  margin: 20px 0px 20px 0px;
  padding: 20px;
  background: lighten($brand-color, 70%);
  width: 98% !important;
  display: block !important;

  h3 {
    padding-bottom: 20px;
    margin-top: 0 !important;
  }
}

.bookingsLeft {
  padding-top: 10px;
  font-weight: bold;
}
.redirectButton {
  margin-top: 15px;
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
  display: block !important;
}
@media (max-width: 768px) {
  .bookingsTable {
    overflow: scroll;
  }
  .PayForClass {
    display: flex;
  }

  :global {
    .grommetux-table {
      td,
      th {
        padding: 11px 5px;
        text-align: left;
        &:first-child {
          padding-left: 15px;
        }
      }
    }

    .grommetux-control-icon {
      width: 26px;
      height: 26px;
    }
  }
}

.addToCalender {
  border-color: #9b7c38;
  background-color: #9b7c38;
  color: #fff !important;
  font-size: 1.1875rem;
  line-height: 24px;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  padding-left: 6px;
  padding-right: 6px;
  width: 188px;
  padding-top: 5px;
  padding-bottom: 5px;

  button {
    color: white;
  }
}
