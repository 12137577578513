// (C) Copyright 2014-2018 Hewlett Packard Enterprise Development LP

.#{$grommet-namespace}button {
  @include basic-button();

  &--focus {
    border: $button-border-width solid $focus-border-color;
    box-shadow: 0 0 1px 1px $focus-border-color;
  }

  @include background-context-color($button-text-color, $button-text-color);
}

.#{$grommet-namespace}button--hover-background:hover {
  background-color: $hover-background-color;
  color: $hover-text-color;

  #{$dark-background-context} {
    background-color: $colored-hover-background-color;
    color: $active-colored-text-color;
  }

  #{$light-background-context} {
    background-color: $hover-background-color;
    color: $hover-text-color;
  }
}

.#{$grommet-namespace}button:hover {
  .#{$grommet-namespace}control-icon {
    @include icon-color($active-icon-color);
    transition: none;
  }

  #{$dark-background-context} {
    .#{$grommet-namespace}control-icon {
      @include icon-color($active-colored-icon-color);
    }
  }

  #{$light-background-context} {
    .#{$grommet-namespace}control-icon {
      @include icon-color($active-icon-color);
    }
  }
}

.#{$grommet-namespace}button__icon {
  display: inline-block;

  .#{$grommet-namespace}control-icon {
    vertical-align: bottom;
  }
}

.#{$grommet-namespace}button--secondary:not(.#{$grommet-namespace}button--focus) {
  border-color: nth($brand-accent-colors, 2);
  background-color: nth($brand-accent-colors, 2);
  color: #fff;
}

.#{$grommet-namespace}button--secondary:hover {
  box-shadow: 0px 0px 0px 2px nth($brand-accent-colors, 2) !important;
}

.#{$grommet-namespace}button--accent:not(.#{$grommet-namespace}button--focus) {
  border-color: nth($brand-accent-colors, 2);
  background-color: nth($brand-accent-colors, 2);
}

.#{$grommet-namespace}button--critical:not(.#{$grommet-namespace}button--focus) {
  border-color: $button-critical-color;
}

.#{$grommet-namespace}button--align-start {
  text-align: left;

  html.rtl & {
    text-align: right;
  }
}

.#{$grommet-namespace}button--plain:not(.#{$grommet-namespace}button--box) {
  padding: 0;
}

.#{$grommet-namespace}button--plain {
  width: auto;
  height: auto;
  min-width: 0;
  max-width: none;
  text-align: inherit;
  font-weight: inherit;

  .#{$grommet-namespace}button__label {
    margin: 0 halve($inuit-base-spacing-unit);
  }

  .#{$grommet-namespace}button__icon {
    padding: halve($inuit-base-spacing-unit) - $button-border-width;

    + .#{$grommet-namespace}button__label {
      margin-left: 0;
    }
  }
}

.#{$grommet-namespace}button--plain:not(.#{$grommet-namespace}button--focus) {
  border: $button-border-width solid transparent;
}

.#{$grommet-namespace}button:not(.#{$grommet-namespace}button--plain) {
  .#{$grommet-namespace}button__icon {
    // @include background-context-icon($button-text-color, $button-text-color);

    + .#{$grommet-namespace}button__label {
      margin-left: halve($inuit-base-spacing-unit);
    }
  }
}

.#{$grommet-namespace}button--primary {
  &:not(.#{$grommet-namespace}button--focus) {
    border-color: $brand-color;
  }
  background-color: $brand-color;
  color: $active-colored-text-color;

  &:hover:not(.#{$grommet-namespace}button--disabled) {
    color: $active-colored-text-color;

    .#{$grommet-namespace}button__icon .#{$grommet-namespace}control-icon {
      @include icon-color($active-colored-icon-color);
    }
  }

  .#{$grommet-namespace}control-icon {
    @include icon-color($active-colored-icon-color);
  }

  @include background-context-color($active-colored-text-color, $active-colored-text-color);

  @include background-context-icon($active-colored-text-color, $active-colored-text-color);
}

.#{$grommet-namespace}button--primary:hover {
  box-shadow: 0px 0px 0px 2px $brand-color !important;
}

.#{$grommet-namespace}button--disabled {
  opacity: 0.3;
  cursor: default;
}

.#{$grommet-namespace}button:not(.#{$grommet-namespace}button--fill) {
  flex: 0 0 auto;
}

.#{$grommet-namespace}button--fill {
  width: 100%;
  max-width: none;
  // used to add full height for button inside a flex container
  flex-grow: 1;
}

a.#{$grommet-namespace}button {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}
