.configContainer {
  background: lightgray;
  padding: 10px 20px;
}
.checkboxesContainer {
  padding: 15px 0px;
}
.checkbox {
  display: block;
  margin: 20px 0px;
}
