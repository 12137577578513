.attendenceReportLabel {
  font-weight: 700;
}
.attendenceReport__filter {
  border: solid 1px rgba(0, 0, 0, 0.15);
  padding: 1em;
  margin-bottom: 1em;
  display: flex;
  & > div {
    margin: 0 20px;
  }
}
.attendenceReport__filter__date {
  width: 88%;
}
.attendenceReport__filter__gym {
  margin-top: 20px;
  width: 88%;
}
.attendenceReport__msg {
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .attendenceReport__filter {
    display: block;
    & > div {
      margin: 0;
    }
  }
  .attendenceReport__filter__date {
    margin-top: 20px;
    width: 100%;
  }
  .attendenceReport__filter__checkbox {
    margin-top: 20px;
  }
  .attendenceReport__filter__gym {
    width: 100%;
  }
  .attendenceReport__filter__name {
    width: 100%;
  }
}
