.currentStatus {
  min-height: 96px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0;
}

.currentStatusBorder {
  border: 1px solid #d7d7d7;
  border-left: none;
  border-right: none;
}

.currentStatus__info,
.currentStatus__button {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info__statusContainer {
  margin-bottom: 12px;
}

.info__text {
  margin-bottom: 12px;
}

.info__text,
.info__activeDesktop,
.info__activeMobile {
  color: #666666;
  line-height: 24px;
}

.info__activeDesktop,
.info__activeMobile,
.info__notActive,
.info__active {
  margin-right: 8px;
}

.info__notActive {
  color: #d0021b;
}

.info__activeDesktop {
  display: none;
}

.info__planName {
  display: none;
}

.limitsContainer {
  display: flex;
  flex-direction: column;
}
.info {
  color: #d0021b;
  font-size: 1.1em;
  display: block;
}

@media screen and (min-width: 768px) {
  .currentStatus {
    padding: 0;
  }

  .info__text,
  .info__statusContainer {
    margin-bottom: 0;
  }

  .currentStatus__info {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 300px;
  }

  .currentStatus__infoActive {
    width: 100%;
  }

  .info__activeDesktop {
    display: block;
  }

  .info__activeMobile {
    display: none;
  }

  .info__planName {
    display: initial;
    font-weight: 700;
    margin-left: 10px;
  }
}
