.gymSelector {
  text-align: left;
  label {
    display: block;
    padding-bottom: 10px;
  }
}

.fullAccess {
  margin: 0px 0px 20px 0px;
  font-weight: bold;
}
