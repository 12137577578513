.singleClass {
  padding: 16px 8px 35px 8px;
  border-bottom: 2px solid #d7d7d7;
}

.time {
  font-size: 12px;
  color: #9b7c38;
  line-height: 24px;
}

.name {
  font-weight: 700;
  font-size: 18px;
  color: #333333;
  line-height: 24px;
  flex-shrink: 0;
  margin-right: 10px;
  max-width: 130px;
}

.name__small {
  font-size: 12px;
}

.pointer {
  cursor: pointer;
}

.additionalInfo {
  color: #4e443d !important;
  font-size: 10px;
  color: #666666;
}
.block {
  flex: 100 !important;
}

.additionalInfoButtonStyle {
  min-height: 32px;
  display: flex;
  align-items: center;
}

.location,
.limit {
  font-size: 12px;
  color: #666666;
  line-height: 24px;
}

.location {
  margin-right: 10px;
}

.informationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.iconContainer {
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.icon {
  cursor: pointer;
}

.timetableButton {
  padding: 2px 11px !important;
  width: 100%;
  font-size: 16px;
}

.adminButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  * {
    margin-left: 10px;
  }
}

.label {
  display: inline-block;
  font-weight: 700;
  margin-right: 5px;
}
.hidden {
  background-color: #dcdcdc;
}

@media screen and (min-width: 555px) {
  .iconContainer {
    width: 50px;
    height: initial;
    display: flex;
    flex-direction: row;
  }

  .icon {
    width: 16px;
    height: 16px;

    &:first-child {
      margin: 0 10px;
    }
  }
}
