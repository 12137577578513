@import '../../../../brandConfig/colors/colors.scss';
.date {
  font-weight: bold;
  .dayName {
    font-weight: normal;
    float: right;
  }
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.eventContainer {
  .event {
    background: $brand-color;
    color: #fff;
    padding: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    p {
      margin: 0;
      font-size: 10px;
    }
    h5 {
      margin: 0;
    }
  }
}
