.root {
  @media screen and (max-width: 768px) {
    overflow: scroll;
  }
  :global {
    .ss-modal__buttons-container {
      display: block !important;
      margin: 20px auto;
      button {
        height: auto;
        margin: 5px auto;
      }
    }
  }
  .actionColumn {
    float: right !important;
    width: 100%;
    p {
      font-weight: bold !important;
      text-align: center !important;
      margin: 5px 0px;
    }

    :global {
      button {
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;
      }
    }
  }
}
.dayHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 33px;
  img {
    padding: 15px;
    cursor: pointer;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
}

.vodAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nowrap {
  white-space: nowrap;
}

.modalMsg {
  font-size: 1.3em;
  padding-bottom: 10px;
  text-align: center;
  display: block;
}

.modalButtons {
  width: 100%;
  min-width: 100%;
  margin-bottom: 15px;
}

.cancelButton {
  width: 100%;
  min-width: 100%;
  font-weight: 700px;
  background-color: #414142 !important;
}

.classTakenPlaceInfo {
  color: gray !important;
  margin: 0px !important;
  font-weight: bold !important;
}

.adminActionButtons {
  display: flex;
  justify-content: space-around;

  margin: 0px 0px 0px auto;
  span {
    width: 250px;
  }

  :last-child {
    display: flex;
    // width: 50px;
  }
}

.arrowRight {
  cursor: pointer;
  z-index: 1000;
}

.arrowLeft {
  cursor: pointer;
  z-index: 1000;
}

@media screen and (max-width: 768px) {
  :global {
    .grommetux-table {
      td,
      th {
        padding: 11px 5px;
        text-align: left;
        min-width: 50px;
        &:first-child {
          padding-left: 15px;
        }
        &:last-child {
          padding-right: 0px;
        }
      }
    }
  }
}
