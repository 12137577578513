.quantityButton {
  min-width: auto !important;
  margin-right: 10px;
}

.quantityValue {
  width: 40px !important;
  display: inline-block;
  padding-left: 10px;
}

.kitName {
  display: inline-block;
  word-wrap: break-word !important;
}
.singleKitBox {
  padding: 10px 0px;
}

.singleKit {
  display: inline-block;
}

.modal {
  margin: 35px;
}
