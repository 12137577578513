.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
}

.vodAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.passButton {
  width: 100%;
}
