.reportLabel {
  font-weight: 700;
}
.report__filter {
  border: solid 1px rgba(0, 0, 0, 0.15);
  padding: 1em;
  margin-bottom: 1em;
  display: flex;
  & > div {
    margin: 0 20px;
  }
}
.report__filter__name {
  margin-top: 20px;
}
.report__filter__trainer {
  margin-top: 20px;
}
.report__type {
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .report__filter {
    display: block;
    & > div {
      margin: 0;
    }
  }
  .report__filter__date {
    margin-top: 20px;
  }
  .report__filter__checkbox {
    margin-top: 20px;
  }
  .report__filter__input {
    width: 100%;
  }
}
