@import '../../brandConfig/font-family/fonts.scss';
.modalContainer {
  font-family: $brand-font-family;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 10px 30px 10px 10px;
  //margin-right: -25%;
  min-width: 900px;
  //max-height: 500px;
  transform: translate(-50%, -50%);
  z-index: 1002;
}
