.listHeader {
  font-size: 19px !important;
  margin: 0 0 13px 0;
}

.tableListHeader {
  margin: 0 !important;
}

.addNewButton {
  display: flex;
  justify-content: space-between;
  button {
    width: 201px;
  }
}
