.label {
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}

.StripeElement {
  height: 40px;
  padding: 10px 12px;
  border: 1px solid #dddddd;
  margin-bottom: 40px;
  background: #ffffff;
  max-width: 100%;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.addBtn {
  button {
    min-width: 100% !important;
    width: 100% !important;
  }
}
.container {
  width: 100%;
}
