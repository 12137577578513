.flexContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.calendarHeader {
  justify-content: space-between;
  margin-bottom: 10px;
  .trainerContainer {
    display: flex;
    align-items: center;
    .resetTrainer {
      cursor: pointer;
      margin-left: 10px;
    }
  }
}
.calendarDate {
  display: flex;
  align-items: center;
  .date {
    margin: 0 10px;
    font-size: 16px;
    font-weight: bold;
  }
  svg {
    cursor: pointer;
  }
}
.datePicker {
  input {
    display: none;
  }
  button {
    margin-right: 10px;
    border: none !important;
    box-shadow: none !important;
    position: initial;
    transform: none;
  }
}
@media only screen and (max-width: 600px) {
  .flexContainer {
    width: 100%;
    justify-content: center;
  }
}
